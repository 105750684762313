<template>
  <vx-card title="Edit Route Plan">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="code" :readonly="true"></vs-input>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          :custom-label="customLabel"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Zone</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedZone"
          :options="optionZone"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          :custom-label="customLabel"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Group</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedRouteGroup"
          :options="optionRouteGroup"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <!-- <div v-if="this.selectedRouteGroup">
      <div
        class="vx-row mb-6"
        style="width: 50%"
        v-if="this.selectedRouteGroup.code == 'Monthly'"
      >
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Month</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <datepicker
            name="month"
            format="MMM yyyy"
            :inline="false"
            v-model="month"
            minimum-view="month"
            placeholder="Select Month"
            :disabled="true"
          ></datepicker>
        </div>
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <l-map style="height: 400px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            :lat-lng="customerAddress.latLng"
            :key="index"
            v-for="(customerAddress, index) in customerAddresses"
          >
            <l-icon :icon-anchor="staticAnchor">
              <div
                style="
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  font-size: 15px;
                  color: #000;
                  line-height: 17px;
                  text-align: center;
                  background: #fff;
                  margin-left: 5px;
                  margin-top: -5px;
                "
              >
                {{ customerAddress.sequence }}
              </div>
              <div :style="{ fill: customerAddress.color }">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  height="50px"
                  viewBox="0 0 425.963 425.963"
                  xml:space="preserve"
                  style="margin-left: -10px; margin-top: -27px"
                >
                  <g>
                    <path
                      d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
		c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
		c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
		C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
		c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"
                    />
                  </g>
                </svg>
              </div>
            </l-icon>
            <l-tooltip>
              <div>
                <b>{{ customerAddress.label }}</b>
                <br />{{ customerAddress.customerGroup }} <br />{{
                  customerAddress.address
                }}
              </div>
            </l-tooltip>
          </l-marker>

          <template v-for="(iteration, iterationIndex) in iterations">
            <l-polyline
              v-for="(o, oIndex) in iteration.route_group_lines"
              :key="'polyline-' + iterationIndex + '-' + oIndex"
              :lat-lngs="o.polyLine.latlngs"
              :color="o.polyLine.color"
            ></l-polyline>
          </template>
        </l-map>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th width="100px">Week</th>
              <th width="300px">Route Schedule</th>
              <th width="20px">Generated</th>
              <th width="20px">No</th>
              <th width="400px">Customer</th>
              <th width="100px">Dstr Channel</th>
              <th width="400px">Customer Ship-to</th>
              <th width="400px">Ship-to Address</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="iterationIndex"
              v-for="(iteration, iterationIndex) in iterations"
              style="border-bottom: 3px solid #f8f8f8"
            >
              <td
                class="td vs-table--td"
                width="100px"
                style="text-align: center"
              >
                <input
                  v-if="scheduleType == 'Monthly'"
                  type="number"
                  min="1"
                  max="52"
                  class="vs-inputx vs-input--input normal"
                  v-model="iterations[iterationIndex].week"
                />
                <div v-else>{{ iterationIndex + 1 }}</div>
              </td>
              <td
                colspan="7"
                style="
                  padding-top: 0px;
                  padding-bottom: 0px;
                  padding-left: 0px;
                  border-top: 0;
                "
              >
                <table>
                  <tr
                    :key="routeGroupLineIndex"
                    v-for="(routeGroupLine, routeGroupLineIndex) in iterations[
                      iterationIndex
                    ].route_group_lines"
                    style="border-bottom: 3px solid #f8f8f8"
                  >
                    <td class="td vs-table--td" width="300px">
                      <vs-button
                        size="small"
                        :color="routeGroupLine.polyLine.color"
                      />
                      {{ routeGroupLine.code }}
                    </td>
                    <td class="td vs-table--td" width="50pxx">
                      {{ routeGroupLine.is_generated ? "Yes" : "No" }}
                    </td>
                    <td>
                      <table>
                        <tr
                          v-for="(
                            customer_line, customer_line_index
                          ) in iterations[iterationIndex].route_group_lines[
                            routeGroupLineIndex
                          ].customer_lines"
                          :key="customer_line_index"
                        >
                          <td
                            class="td vs-table--td"
                            width="20px"
                            style="
                              padding-top: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                          >
                            {{ customer_line_index + 1 }}
                          </td>
                          <td
                            class="td vs-table--td"
                            width="400px"
                            style="
                              padding-top: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                          >
                            <v-select
                              disabled
                              label="name"
                              :filterable="false"
                              :options="
                                iterations[iterationIndex].route_group_lines[
                                  routeGroupLineIndex
                                ].customer_lines[customer_line_index]
                                  .option_customer
                              "
                              v-model="
                                iterations[iterationIndex].route_group_lines[
                                  routeGroupLineIndex
                                ].customer_lines[customer_line_index]
                                  .selected_customer
                              "
                              @input="
                                (val) => {
                                  onChangeCustomer(
                                    val,
                                    iterationIndex,
                                    routeGroupLineIndex,
                                    customer_line_index
                                  );
                                }
                              "
                              @search="
                                (search, loading) => {
                                  onSearchCustomer(
                                    search,
                                    loading,
                                    iterationIndex,
                                    routeGroupLineIndex,
                                    customer_line_index
                                  );
                                }
                              "
                            >
                              <template slot="option" slot-scope="option">
                                {{ option.code }} {{ option.name }}
                              </template>
                              <template
                                slot="selected-option"
                                slot-scope="option"
                              >
                                {{ option.code }} {{ option.name }}
                              </template>
                            </v-select>
                          </td>
                          <td
                            width="100px"
                            style="
                              padding-top: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                          >
                            <input
                              type="text"
                              readonly="readonly"
                              class="vs-inputx vs-input--input normal"
                              style="border: 1px solid rgba(0, 0, 0, 0.2)"
                              :value="
                                getCustomerCategoryName(
                                  iterations[iterationIndex].route_group_lines[
                                    routeGroupLineIndex
                                  ].customer_lines[customer_line_index]
                                    .selected_customer
                                )
                              "
                            />
                          </td>
                          <td
                            width="400px"
                            style="
                              padding-top: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                          >
                            <v-select
                              disabled
                              label="code"
                              :filterable="false"
                              :options="
                                iterations[iterationIndex].route_group_lines[
                                  routeGroupLineIndex
                                ].customer_lines[customer_line_index]
                                  .option_customer_address
                              "
                              v-model="
                                iterations[iterationIndex].route_group_lines[
                                  routeGroupLineIndex
                                ].customer_lines[customer_line_index]
                                  .selected_customer_address
                              "
                              @input="
                                (val) => {
                                  onChangeCustomerAddress(
                                    val,
                                    iterationIndex,
                                    routeGroupLineIndex,
                                    customer_line_index
                                  );
                                }
                              "
                              @search="
                                (search, loading) => {
                                  onSearchCustomerAddress(
                                    search,
                                    loading,
                                    iterations[iterationIndex]
                                      .route_group_lines[routeGroupLineIndex]
                                      .customer_lines[customer_line_index]
                                      .selected_customer.id,
                                    iterationIndex,
                                    routeGroupLineIndex,
                                    customer_line_index
                                  );
                                }
                              "
                            >
                              <template slot="option" slot-scope="option">
                                {{ option.code }} {{ option.contact_name }}
                              </template>
                              <template
                                slot="selected-option"
                                slot-scope="option"
                              >
                                {{ option.code }} {{ option.contact_name }}
                              </template>
                            </v-select>
                          </td>
                          <td
                            width="400px"
                            style="
                              padding-top: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                          >
                            <input
                              type="text"
                              readonly="readonly"
                              class="vs-inputx vs-input--input normal"
                              style="border: 1px solid rgba(0, 0, 0, 0.2)"
                              :value="
                                getCustomerAddressAddress(
                                  iterations[iterationIndex].route_group_lines[
                                    routeGroupLineIndex
                                  ].customer_lines[customer_line_index]
                                    .selected_customer_address
                                )
                              "
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";
import _ from "lodash";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

import "leaflet/dist/leaflet.css";

import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPolyline,
  LIcon,
} from "vue2-leaflet";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    vSelect,
    Datepicker,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/route-plan",
        optionTerritory: [],
        selectedTerritory: null,
        optionZone: [],
        selectedZone: null,
        optionRouteGroup: [],
        selectedRouteGroup: null,
        routeGroupID: null,
        code: "",
        description: "",
        optionRouteGroupLine: [],
        selectedRouteGroupLine: null,
        optionCustomer: [],
        month: null,
        routePlanID: null,

        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        center: [-6.2, 106.816666],
        customerAddresses: [],
        polyLines: [],
        staticAnchor: [16, 37],

        locale: "en",

        routeGroupLines: [],
        iterations: [],
        scheduleType: "",
      };
    },
    handleBack() {
      this.$router.push({
        name: "route-plan",
      });
    },
    customLabel(data) {
      return data.code + " " + data.name;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = null;
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getZone() {
      if (this.selectedTerritory) {
        if (this.selectedTerritory.code) {
          this.$vs.loading();
          this.$http
            .get(this.baseUrl + "/zone", {
              params: {
                order: "name",
                sort: "asc",
                territory_code: this.selectedTerritory.code,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  this.optionZone = resp.data.records;
                  if (this.optionZone.length > 0) {
                    this.selectedZone = this.optionZone[0];
                  } else {
                    this.optionZone = [];
                    this.selectedZone = null;
                  }
                } else {
                  this.optionZone = [];
                  this.selectedZone = null;
                }
                this.$vs.loading.close();
              } else {
                this.optionZone = [];
                this.selectedZone = null;
                this.$vs.loading.close();
              }
            });
        } else {
          this.optionZone = [];
          this.selectedZone = {};
        }
      }
    },
    getRouteGroup() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/route-group", {
          params: {
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionRouteGroup = resp.data.records;
              // if (this.optionRouteGroup.length > 0) {
              //   this.selectedRouteGroup = this.optionRouteGroup[0];
              // } else {
              //   this.optionRouteGroup = [];
              //   this.selectedRouteGroup = {};
              // }
            } else {
              this.optionRouteGroup = [];
              this.selectedRouteGroup = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getRouteGroupLine() {
      if (this.selectedRouteGroup.id) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/route-group-line", {
            params: {
              order: "id",
              sort: "asc",
              route_group_id: this.selectedRouteGroup.id,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                let routeGroupLines = [];
                resp.data.records.forEach((element) => {
                  let customerLines = [];
                  customerLines.push({
                    customer_id: null,
                    customer_address_id: null,
                    selected_customer: null,
                    option_customer: [],
                    selected_customer_address: null,
                    option_customer_address: [],
                  });
                  element.customer_lines = customerLines;
                  element.polyline = {
                    latlngs: [],
                    color: "",
                  };

                  routeGroupLines.push(element);
                });
                this.routeGroupLines = routeGroupLines;
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    async getRouteGroupLine2(routeGroupID) {
      let resp = await this.$http.get(this.baseUrl + "/route-group-line", {
        params: {
          order: "id",
          sort: "asc",
          route_group_id: routeGroupID,
        },
      });

      if (resp.code == 200) {
        return resp.data.records;
      } else {
        return [];
      }
    },
    getRoutePlan(id) {
      this.$http.get(this.baseUrl + "/" + id + "/edit").then((resp) => {
        if (resp.code == 200) {
          this.code = resp.data.code;
          this.description = resp.data.description;

          // if (resp.data.schedule_type == "Monthly") {
          //   this.month = new Date(resp.data.date);
          // }
          this.routeGroupID = resp.data.route_group_id;
          this.setSelectedTerritory(resp.data.territory_id);
          this.setSelectedZone(resp.data.zone_id);
          this.setSelectedRouteGroup(resp.data.route_group_id);

          this.getRouteGroupLine2(resp.data.route_group_id).then(
            (routeGroupLines) => {
              let tempIterations = [];
              this.routeGroupLines = routeGroupLines;

              resp.data.iterations.forEach((tempIteration) => {
                let tempRuoteGroupLines = [];
                routeGroupLines.forEach((d) => {
                  var hex =
                    "#" +
                    ((Math.random() * 0xffffff) << 0)
                      .toString(16)
                      .padStart(6, "0");

                  let route_plan_lines = tempIteration.route_plan_lines.filter(
                    (route_plan_line) => {
                      return route_plan_line.route_group_line_id == d.id;
                    }
                  );
                  let customer_lines = [
                    {
                      customer_id: null,
                      customer_address_id: null,
                      selected_customer: null,
                      option_customer: [],
                      selected_customer_address: null,
                      option_customer_address: [],
                    },
                  ];

                  if (route_plan_lines.length != 0) {
                    customer_lines = route_plan_lines[0].customer_lines;
                  }

                  tempRuoteGroupLines.push({
                    id: d.id,
                    code: d.code,
                    is_generated: d.is_generated,
                    odd_even: d.odd_even,
                    customer_lines: customer_lines,
                    polyLine: {
                      latlngs: [],
                      color: hex,
                    },
                  });
                });

                tempIterations.push({
                  week: tempIteration.week,
                  month: "",
                  route_group_lines: tempRuoteGroupLines,
                });
              });

              console.log(tempIterations);

              this.iterations = tempIterations;
            }
          );
        }
      });
    },
    setSelectedTerritory(territorryID) {
      let cek = this.optionTerritory.filter((el) => {
        if (el.id == territorryID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedTerritory = cek[0];
      } else {
        this.selectedTerritory = null;
      }
    },
    setSelectedZone(zoneID) {
      let cek = this.optionZone.filter((el) => {
        if (el.id == zoneID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedZone = cek[0];
      } else {
        this.selectedZone = null;
      }
    },
    setSelectedRouteGroup(routeGroupID) {
      let cek = this.optionRouteGroup.filter((el) => {
        if (el.id == routeGroupID) {
          return true;
        }
        return false;
      });

      console.log(cek);
      if (cek.length > 0) {
        this.selectedRouteGroup = cek[0];
      } else {
        this.selectedRouteGroup = null;
      }
    },

    setCustomerAddress(
      element,
      iterationIndex,
      routeGroupLineIndex,
      customerLineIndex
    ) {
      if (element.selected_customer) {
        if (element.selected_customer.id) {
          if (element.selected_customer.id != element.customer_id) {
            if (this.selectedTerritory.id) {
              this.$http
                .get(this.baseUrl + "/customer-address", {
                  params: {
                    order: "id",
                    sort: "asc",
                    territory_id: this.selectedTerritory.id,
                    zone_id: this.selectedZone ? this.selectedZone.id : null,
                    customer_id: element.selected_customer.id,
                  },
                })
                .then((resp) => {
                  if (resp.code == 200) {
                    let customerAddresses = resp.data.records;
                    this.iterations[iterationIndex].route_group_lines[
                      routeGroupLineIndex
                    ].customer_lines[
                      customerLineIndex
                    ].option_customer_address = customerAddresses;

                    if (customerAddresses.length > 0) {
                      var firstCustomerAddress = customerAddresses[0];
                      let cek = this.iterations[
                        iterationIndex
                      ].route_group_lines[
                        routeGroupLineIndex
                      ].customer_lines.filter((el, index) => {
                        if (
                          el.selected_customer_address &&
                          firstCustomerAddress
                        ) {
                          if (
                            el.selected_customer_address.id ==
                              firstCustomerAddress.id &&
                            index != customerLineIndex
                          ) {
                            return true;
                          }
                        }
                        return false;
                      });

                      if (cek.length == 0) {
                        this.iterations[iterationIndex].route_group_lines[
                          routeGroupLineIndex
                        ].customer_lines[
                          customerLineIndex
                        ].selected_customer_address = firstCustomerAddress;

                        this.iterations[iterationIndex].route_group_lines[
                          routeGroupLineIndex
                        ].customer_lines[
                          customerLineIndex
                        ].customer_address_id = firstCustomerAddress.id;
                      }
                    }

                    this.iterations[iterationIndex].route_group_lines[
                      routeGroupLineIndex
                    ].customer_lines[customerLineIndex].customer_id =
                      element.selected_customer.id;
                  } else {
                    this.resetSelectedCustomerAddress(
                      iterationIndex,
                      routeGroupLineIndex,
                      customerLineIndex
                    );
                  }
                });
            } else {
              this.resetSelectedCustomerAddress(
                iterationIndex,
                routeGroupLineIndex,
                customerLineIndex
              );
            }
          }
        } else {
          this.resetSelectedCustomerAddress(
            iterationIndex,
            routeGroupLineIndex,
            customerLineIndex
          );
        }
      } else {
        this.resetSelectedCustomerAddress(
          iterationIndex,
          routeGroupLineIndex,
          customerLineIndex
        );
      }
    },
    onChangeCustomer(
      val,
      iterationIndex,
      routeGroupLineIndex,
      customerLineIndex
    ) {
      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines[customerLineIndex].selected_customer = val;

      this.setCustomerAddress(
        this.iterations[iterationIndex].route_group_lines[routeGroupLineIndex]
          .customer_lines[customerLineIndex],
        iterationIndex,
        routeGroupLineIndex,
        customerLineIndex
      );
    },
    onChangeCustomerAddress(
      val,
      iterationIndex,
      routeGroupLineIndex,
      customer_line_index
    ) {
      let cek = this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines.filter((el, index) => {
        if (el.selected_customer_address && val) {
          if (
            el.selected_customer_address.id == val.id &&
            index != customer_line_index
          ) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        alert("Customer ship-to address already add");
        this.iterations[iterationIndex].route_group_lines[
          routeGroupLineIndex
        ].customer_lines[customer_line_index].selected_customer_address = null;

        this.iterations[iterationIndex].route_group_lines[
          routeGroupLineIndex
        ].customer_lines[customer_line_index].customer_address_id = null;
      } else {
        this.iterations[iterationIndex].route_group_lines[
          routeGroupLineIndex
        ].customer_lines[customer_line_index].selected_customer_address = val;

        if (val) {
          this.iterations[iterationIndex].route_group_lines[
            routeGroupLineIndex
          ].customer_lines[customer_line_index].customer_address_id = val.id;
        } else {
          this.iterations[iterationIndex].route_group_lines[
            routeGroupLineIndex
          ].customer_lines[customer_line_index].customer_address_id = null;
        }
      }
    },
    getCustomerCategoryName(selectedCustomer) {
      return selectedCustomer ? selectedCustomer.customer_category_name : "";
    },
    getCustomerAddressAddress(selectedCustomerAddress) {
      let address = "";
      if (selectedCustomerAddress) {
        if (selectedCustomerAddress.address) {
          address += selectedCustomerAddress.address;
        }

        if (selectedCustomerAddress.sub_district) {
          address += ", " + selectedCustomerAddress.sub_district;
        }

        if (selectedCustomerAddress.district) {
          address += ", " + selectedCustomerAddress.district;
        }

        if (selectedCustomerAddress.city) {
          address += ", " + selectedCustomerAddress.city;
        }

        if (selectedCustomerAddress.province) {
          address += ", " + selectedCustomerAddress.province;
        }

        if (selectedCustomerAddress.Indonesia) {
          address += ", " + selectedCustomerAddress.Indonesia;
        }

        if (selectedCustomerAddress.postal_code) {
          address += " " + selectedCustomerAddress.postal_code;
        }
      }

      return address;
    },
    resetSelectedCustomerAddress(
      iterationIndex,
      routeGroupLineIndex,
      customerLineIndex
    ) {
      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines[customerLineIndex].option_customer_address = [];

      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines[customerLineIndex].selected_customer_address = null;

      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines[customerLineIndex].customer_id = null;
    },

    onSearchCustomer(
      search,
      loading,
      iterationIndex,
      routeGroupLineIndex,
      customerLineIndex
    ) {
      if (search.length) {
        loading(true);
        this.searchCustomer(
          loading,
          search,
          this,
          iterationIndex,
          routeGroupLineIndex,
          customerLineIndex
        );
      }
    },
    onSearchCustomerAddress(
      search,
      loading,
      customerID,
      iterationIndex,
      routeGroupLineIndex,
      customerLineIndex
    ) {
      if (search.length) {
        loading(true);
        this.searchCustomerAddress(
          loading,
          search,
          this,
          customerID,
          iterationIndex,
          routeGroupLineIndex,
          customerLineIndex
        );
      }
    },
    searchCustomer: _.debounce(
      (
        loading,
        search,
        t,
        iterationIndex,
        routeGroupLineIndex,
        customerLineIndex
      ) => {
        if (t.selectedTerritory) {
          t.$http
            .get(t.baseUrl + "/customer", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                zone_id: t.selectedZone ? t.selectedZone.id : null,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  t.iterations[iterationIndex].route_group_lines[
                    routeGroupLineIndex
                  ].customer_lines[customerLineIndex].option_customer =
                    resp.data.records;
                } else {
                  t.iterations[iterationIndex].route_group_lines[
                    routeGroupLineIndex
                  ].customer_lines[customerLineIndex].option_customer = [];
                }
                loading(false);
              } else {
                t.iterations[iterationIndex].route_group_lines[
                  routeGroupLineIndex
                ].customer_lines[customerLineIndex].option_customer = [];
                loading(false);
              }
            });
        }
      },
      350
    ),
    searchCustomerAddress: _.debounce(
      (
        loading,
        search,
        t,
        customerID,
        iterationIndex,
        routeGroupLineIndex,
        customerLineIndex
      ) => {
        if (t.selectedTerritory.id && customerID) {
          t.$http
            .get(t.baseUrl + "/customer-address", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                zone_id: t.selectedZone ? t.selectedZone.id : null,
                customer_id: customerID,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                t.iterations[iterationIndex].route_group_lines[
                  routeGroupLineIndex
                ].customer_lines[customerLineIndex].option_customer_address =
                  resp.data.records;
              } else {
                t.iterations[iterationIndex].route_group_lines[
                  routeGroupLineIndex
                ].customer_lines[customerLineIndex].option_customer_address =
                  [];
              }
              loading(false);
            });
        }
      },
      350
    ),
    addCustomerRow(iterationIndex, routeGroupLineIndex) {
      console.log("addCustomerRow", iterationIndex, routeGroupLineIndex);

      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines.push({
        customer_id: null,
        customer_address_id: null,
        selected_customer: null,
        option_customer: [],
        selected_customer_address: null,
        option_customer_address: [],
      });
    },
    removeCustomerRow(
      iterationIndex,
      routeGroupLineIndex,
      customer_line_index
    ) {
      console.log(
        "removeCustomerRow",
        iterationIndex,
        routeGroupLineIndex,
        customer_line_index
      );

      this.iterations[iterationIndex].route_group_lines[
        routeGroupLineIndex
      ].customer_lines.splice(customer_line_index, 1);
    },
    addIteration() {
      console.log("addIteration", this.iterations.length + 1);
      let routeGroupLInes = [];
      this.routeGroupLines.forEach((d) => {
        var hex =
          "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");

        routeGroupLInes.push({
          id: d.id,
          code: d.code,
          is_generated: d.is_generated,
          odd_even: d.odd_even,
          customer_lines: [
            {
              customer_id: null,
              customer_address_id: null,
              selected_customer: null,
              option_customer: [],
              selected_customer_address: null,
              option_customer_address: [],
            },
          ],
          polyLine: {
            latlngs: [],
            color: hex,
          },
        });
      });

      this.iterations.push({
        week: 0,
        month: "",
        route_group_lines: routeGroupLInes,
      });
    },
    removeIteration(index) {
      this.iterations.splice(index, 1);
    },
  },
  created() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      // Do something with the position
      this.center = [latitude, longitude];
      // console.log(this.center);
    };

    const error = () => {
      console.log(error);
    };

    // This will open permission popup
    navigator.geolocation.getCurrentPosition(success, error);
  },
  mounted() {
    this.getTerritory();
    this.routePlanID = this.$route.params.id;
  },
  watch: {
    routePlanID(val) {
      this.getRoutePlan(val);
    },
    routeGroupID(val) {
      if (this.routeGroupID) {
        let cek = this.optionRouteGroup.filter((el) => {
          if (el.id == val) {
            return true;
          }
          return false;
        });
        if (cek.length > 0) {
          this.selectedRouteGroup = cek[0];
        } else {
          this.selectedRouteGroup = null;
        }
      }
    },
    optionRouteGroup(val) {
      if (this.routeGroupID) {
        let cek = val.filter((el) => {
          if (el.id == this.routeGroupID) {
            return true;
          }
          return false;
        });
        if (cek.length > 0) {
          this.selectedRouteGroup = cek[0];
        } else {
          this.selectedRouteGroup = null;
        }
      }
    },
    selectedRouteGroup(val) {
      if (val) {
        this.scheduleType = val.schedule_type;
      }

      this.getRouteGroupLine();
    },
    selectedTerritory() {
      this.getZone();
      this.getRouteGroup();
    },
    iterations: {
      handler: function (val) {
        // let customerAddresses = [];

        // val.forEach((iteration, iterationIndex) => {
        //   console.log("routeGroupLines", iterationIndex, iteration);
        //   iteration.route_group_lines.forEach(
        //     (routeGroupLine, routeGroupLineIndex) => {
        //       let latlngs = [];
        //       routeGroupLine.customer_lines.forEach((customer_line) => {
        //         if (customer_line.selected_customer_address) {
        //           if (
        //             customer_line.selected_customer_address.latitude &&
        //             customer_line.selected_customer_address.longitude
        //           ) {
        //             latlngs.push([
        //               customer_line.selected_customer_address.latitude,
        //               customer_line.selected_customer_address.longitude,
        //             ]);
        //           }
        //         }
        //       });

        //       routeGroupLine.latlngs = latlngs;

        //       routeGroupLine.customer_lines.forEach(
        //         (customer_line, customer_line_index) => {
        //           if (customer_line.selected_customer_address) {
        //             if (
        //               customer_line.selected_customer_address.latitude &&
        //               customer_line.selected_customer_address.longitude
        //             ) {
        //               customerAddresses.push({
        //                 label:
        //                   customer_line.selected_customer_address.code +
        //                   " " +
        //                   customer_line.selected_customer_address.contact_name,
        //                 latLng: [
        //                   parseFloat(
        //                     customer_line.selected_customer_address.latitude
        //                   ),
        //                   parseFloat(
        //                     customer_line.selected_customer_address.longitude
        //                   ),
        //                 ],
        //                 address: this.getCustomerAddressAddress(
        //                   customer_line.selected_customer_address
        //                 ),
        //                 customerGroup:
        //                   customer_line.selected_customer_address
        //                     .customer_group_code +
        //                   " " +
        //                   customer_line.selected_customer_address
        //                     .customer_group_name,
        //                 color:
        //                   this.iterations[iterationIndex].route_group_lines[
        //                     routeGroupLineIndex
        //                   ].polyLine.color,
        //                 sequence: customer_line_index + 1,
        //               });

        //               this.center = [
        //                 parseFloat(
        //                   customer_line.selected_customer_address.latitude
        //                 ),
        //                 parseFloat(
        //                   customer_line.selected_customer_address.longitude
        //                 ),
        //               ];
        //             }
        //           }
        //         }
        //       );
        //     }
        //   );
        // });

        // this.customerAddresses = customerAddresses;
        console.log("iterations", val);
      },
      deep: true,
    },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}

.multiselect__content-wrapper {
  z-index: 10;
}
</style>
